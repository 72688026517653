import { Observable } from 'rxjs';

import { Period, UserTimezone } from '@daikin-tic/dxone-com-lib';

import { InspectionResult, InspectionResultUpdateParams } from '../models/inspection-result.model';

export abstract class InspectionResultUsecase {
  abstract get inspectionResults$(): Observable<InspectionResult[]>;
  abstract get period$(): Observable<Period>;

  abstract changeTimezone(timezone: UserTimezone): void;
  abstract changePeriod(period: Period): void;
  abstract reload(): void;

  abstract getInspectionResult(inspectionId: string): Observable<string>;
  abstract updateInspectionResult(inspectionId: string, params: InspectionResultUpdateParams): Observable<InspectionResult>;
}
