import { Observable } from 'rxjs';

import { ListResponse } from '@daikin-tic/dxone-com-lib';

import { InspectionResult, InspectionResultQueryParams, InspectionResultUpdateParams } from '../models/inspection-result.model';

export abstract class InspectionResultGateway {
  abstract listInspectionResults(params: InspectionResultQueryParams): Observable<ListResponse<InspectionResult>>;
  abstract getInspectionResult(inspectionId: string): Observable<{ url: string }>;
  abstract updateInspectionResult(inspectionId: string, params: InspectionResultUpdateParams): Observable<InspectionResult>;
}
